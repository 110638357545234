import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link

const Header = ({ user, onLogout }) => {
    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    Bakery Website
                </Typography>
                {user && (
                    <>
                        {user === 'admin' && (
                            <Button color="inherit" component={Link} to="/admin-dashboard">
                                Admin Dashboard
                            </Button>
                        )}
                        <Button color="inherit" onClick={onLogout}>
                            Logout
                        </Button>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
