import React from 'react';
import { Typography, Box } from '@mui/material';

const Footer = () => {
    return (
        <Box
            sx={{
                backgroundColor: '#f5f5f5',
                color: 'black',
                textAlign: 'center',
                py: 2,
                mt: 5,
            }}
        >
            <Typography variant="body2">
                Â© 2024 Bakery - All Rights Reserved
            </Typography>
        </Box>
    );
};

export default Footer;
