import React from 'react';
import ReactDOM from 'react-dom/client';  // Use 'react-dom/client' for React 18
import App from './App';
import { ThemeProvider } from '@mui/material/styles';  // Import MUI theme provider
import theme from './theme';  // Import your theme
import CssBaseline from '@mui/material/CssBaseline';  // Normalize CSS
import './index.css';  // Import CSS

// Create root with React 18's createRoot
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
    </ThemeProvider>
);
