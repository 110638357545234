import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';

const LoginContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '',
    backgroundColor: '#ffffff',
});

const Logo = styled('img')({
    width: '',
    marginTop: '50px'
});

const FormContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '400px',
    padding: '20px',
});

const AdminLogin = ({ onLogin }) => {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = () => {
        if (name === 'admin' && password === 'admin123') {
            onLogin('admin');
        } else if (name === 'user' && password === 'user123') {
            onLogin('user');
        } else {
            alert('Invalid credentials');
        }
    };

    return (
        <LoginContainer>
            {/*<Logo src="../images/logo.png" alt="Bakery Logo" />*/}
            <Typography variant="h3" sx={{ fontWeight: 'bold', marginBottom: 10, marginTop: 10}}>
                Vzw Kompas
            </Typography>

            <FormContainer>
                <TextField
                    id="name"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    id="password"
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                    variant="contained"
                    sx={{ marginTop: 2, backgroundColor: '#673AB7', color: 'white'}}
                    fullWidth
                    onClick={handleLogin}
                >
                    Log In
                </Button>
            </FormContainer>
        </LoginContainer>
    );
};

export default AdminLogin;
