import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#ffffff',  // Brown for bakery theme
        },
        secondary: {
            main: '#6200EE',  // Yellow for highlights
        },
    },
    typography: {
        fontFamily: 'Roboto, Arial',  // Use default fonts
    },
});

export default theme;
