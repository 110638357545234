import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box } from '@mui/material';
import theme from './theme';
import Header from './components/Header';
import Footer from './components/Footer';
import AdminLogin from './pages/AdminLogin';
import LandingPage from './pages/LandingPage';
import AdminDashboard from './pages/AdminDashboard';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; // Import proper routing

function App() {
    const [user, setUser] = useState(null);  // Manage login state

    const handleLogin = (username) => {
        setUser(username);
    };

    const handleLogout = () => {
        setUser(null);
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh'  // Make sure the Box takes the full viewport height
            }}>
                <Router>
                    <Header user={user} onLogout={handleLogout} />

                    {/* Main Content Section */}
                    <Box sx={{ flexGrow: 1, padding: 2 }}>
                        <Routes>
                            {!user && (
                                <Route path="/" element={<AdminLogin onLogin={handleLogin} />} />
                            )}
                            {user && (
                                <>
                                    <Route path="/landing" element={<LandingPage />} />
                                    {user === 'admin' && (
                                        <Route path="/admin-dashboard" element={<AdminDashboard />} />
                                    )}
                                    <Route path="/" element={<Navigate to="/landing" />} />
                                </>
                            )}
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </Box>

                    <Footer />
                </Router>
            </Box>
        </ThemeProvider>
    );
}

export default App;
