import React from 'react';
import { Box, Grid, Button } from '@mui/material';
import { styled } from '@mui/system';

const LandingContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#ffffff',
});

const ActionButton = styled(Button)({
    width: '100%',
    padding: '110px',
    marginBottom: '20px',
    fontSize: '1.5rem',
});

const Logo = styled('img')({
    marginBottom: '40px',
    marginTop: '-400px',
});

const LandingPage = () => {
    return (
        <LandingContainer>
            <Logo src="/images/logo.png" alt="Bakery Logo" />
            <Grid container spacing={3} justifyContent="center" marginTop={-20}>
                <Grid item xs={10} sm={8} md={6}>
                    <ActionButton variant="contained" color="secondary">
                        Afhalen
                    </ActionButton>
                    <ActionButton variant="contained" color="secondary">
                        Bestelling
                    </ActionButton>
                    <ActionButton variant="contained" color="secondary">
                        Overzicht
                    </ActionButton>
                </Grid>
            </Grid>
        </LandingContainer>
    );
};

export default LandingPage;
