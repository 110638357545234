import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const AdminDashboard = () => {
    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Admin Dashboard
            </Typography>
            {/* Add more admin controls here */}
            <Button variant="contained" color="primary">
                Manage Products
            </Button>
            <Button variant="contained" color="primary" sx={{ marginLeft: 2 }}>
                View Orders
            </Button>
        </Box>
    );
};

export default AdminDashboard;
